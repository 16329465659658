import { inject } from '@angular/core';
import type { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { Router } from '@angular/router';
import { DealerFeature, RESPONSE, type ApiTypes } from '@hyundai/ng-common-lib';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';

export function dealerGuard(
    cond: (dealer: ApiTypes.Dealer, route: ActivatedRouteSnapshot) => boolean,
    failCode?: number,
    redirectOnFailUrl = '',
): CanActivateFn {
    return async (route) => {
        const store = inject(Store);
        const router = inject(Router);
        const response = inject(RESPONSE, { optional: true });

        const dealer = await firstValueFrom(store.select(DealerFeature.state.selectLoadedDealer));

        if (cond(dealer, route)) return true;

        if (response) {
            response.redirect(failCode ?? 302, redirectOnFailUrl);
            return false;
        }

        if (redirectOnFailUrl.startsWith('http')) {
            window.location.href = redirectOnFailUrl;
            return false;
        }
        return router.createUrlTree([dealer.slug]);
    };
}
